import ApiService from "@/common/api/api.service";
import helpers from "@/common/utils/helpers";

const UsuarioService = {
  async listar(paginacao) {
    let { data } = await ApiService.get(`Usuario`, { params: paginacao });
    return data;
  },
  async buscarPorId(id) {
    let { data } = await ApiService.get(`Usuario/${id}`);
    return data;
  },
  async salvar(form) {
    form.endereco.cep = helpers.removerMascara(form.endereco.cep);
    form.cpfcnpj = helpers.removerMascara(form.cpfcnpj);
    let result = [];
    if (!form.id) result = await ApiService.post(`Usuario`, form);
    else result = await ApiService.put(`Usuario`, form);
    return result;
  },
  async excluir(id) {
    return await ApiService.delete(`Usuario?id=${id}`);
  },
  async alterarSenha(form) {
    return await ApiService.put(`Usuario/alterar-senha`, form);
  },
  async esqueceuSenha(form) {
    return await ApiService.post(
      `Usuario/solicitar-reset-senha?email=${form.email}`,
      form
    );
  },
  async resetarSenha(form) {
    return await ApiService.put(`Usuario/reset-senha`, form);
  },
  async adicionarPerfil(form) {
    return await ApiService.put(`Usuario/atualizar-perfis`, form);
  },
};

export default UsuarioService;

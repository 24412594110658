var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"left-sidebar",class:_vm.Sidebar_drawer
            ? `show-sidebar bg-${_vm.SidebarColor}`
            : `bg-${_vm.SidebarColor}`},[_c('div',{class:`bg-${_vm.logoColor} navbar-header`},[(_vm.logoColor == 'white')?_c('LogoDark'):_c('LogoLight'),_c('span',{staticClass:"d-block d-lg-none close-sidebar-btn",on:{"click":_vm.showMobileSidebar}},[_c('i',{staticClass:"ti ti-close text-white",attrs:{"title":_vm.$t('MENU.FECHAR')}})])],1),_c('VuePerfectScrollbar',{staticClass:"scrlbar"},[_c('ul',{staticClass:"nav flex-column mb-0"},[_vm._l((_vm.routes),function(route,i){return [(route.header)?_c('li',{key:route.header,staticClass:"nav-item"},[_c('div',{staticClass:"d-flex nav-small-cap"},[_c('i',{class:route.icon}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(route.header))])])]):_vm._e(),(route.children)?_c('li',{key:route.title,staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(route.id),expression:"route.id"}],staticClass:"nav-link d-flex"},[_c('feather',{attrs:{"type":route.icon}}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(_vm.$t(route.i18n) || route.title))]),_c('i',{staticClass:"ti ti-angle-down dd-arrow ml-auto hide-text"})],1),_c('b-collapse',{attrs:{"id":route.id,"accordion":"sidebar-accordion","visible":_vm.$route.path ==
                            `${
                                route.name +
                                _vm.$route.name
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()
                            }`}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_vm._l((route.children),function(subroute,i){return _c('li',{key:i,staticClass:"nav-item"},[(subroute.isExternalLink)?[_c('a',{staticClass:"nav-link d-flex",attrs:{"href":`${subroute.to}`}},[_c('feather',{attrs:{"type":subroute.icon}}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(_vm.$t(subroute.i18n) || subroute.title))])],1)]:[_c('router-link',{staticClass:"nav-link d-flex",attrs:{"to":`${subroute.to}`}},[_c('feather',{attrs:{"type":subroute.icon}}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(_vm.$t(subroute.i18n) || subroute.title))])],1)]],2)})],2)])],1):_vm._e(),(!route.children && !route.header)?_c('li',{key:i,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link d-flex",attrs:{"to":`${route.to}`}},[_c('feather',{attrs:{"type":route.icon}}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(_vm.$t(route.i18n) || route.title))])],1)],1):_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }